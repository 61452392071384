export default {
    // Set user info
    SET_USER_INFO: 'SET_USER_INFO',
    // Logout
    LOG_OUT: 'LOG_OUT',

    // Fetch code list
    ALL_CODE_FETCH_REQUEST: 'ALL_CODE_FETCH_REQUEST',
    ALL_CODE_FETCH_SUCCESS: 'ALL_CODE_FETCH_SUCCESS',
    ALL_CODE_FETCH_FAIL: 'ALL_CODE_FETCH_FAIL',

    // Fetch parking users
    PARKING_USERS_FETCH_REQUEST: 'PARKING_USERS_FETCH_REQUEST',
    PARKING_USERS_FETCH_SUCCESS: 'PARKING_USERS_FETCH_SUCCESS',
    PARKING_USERS_FETCH_FAIL: 'PARKING_USERS_FETCH_FAIL',

    // Fetch parking users
    PARKING_USERS_FETCH_ALL_REQUEST: 'PARKING_USERS_FETCH_ALL_REQUEST',
    PARKING_USERS_FETCH_ALL_SUCCESS: 'PARKING_USERS_FETCH_ALL_SUCCESS',
    PARKING_USERS_FETCH_ALL_FAIL: 'PARKING_USERS_FETCH_ALL_FAIL',
    
    // use cases
    USECASE_FETCH_REQUEST: 'USECASE_FETCH_REQUEST',
    USECASE_FETCH_SUCCESS: 'USECASE_FETCH_SUCCESS',
    USECASE_FETCH_FAIL: 'USECASE_FETCH_FAIL',

    // reservation
    STATUS_FETCH_REQUEST: 'STATUS_FETCH_REQUEST',
    STATUS_FETCH_SUCCESS: 'STATUS_FETCH_SUCCESS',
    STATUS_FETCH_FAIL: 'STATUS_FETCH_FAIL',
    
    // code list
    UPLOAD_REQUEST: 'UPLOAD_REQUEST',
    UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
    UPLOAD_FAIL: 'UPLOAD_FAIL',

};
