import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    errors: '',
    status: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.STATUS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                status: action.payload.status,
            };
        case types.STATUS_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.STATUS_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };
        default:
            return state;
    }
};
