import React from "react";
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {logout} from '../../redux/actions/auth';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useTranslation} from 'react-i18next';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import logo from '../../img/scrumware.png';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';


const TopBar = (props) => {
    const {t} = useTranslation();

    return (
        <AppBar position="absolute">
            <Toolbar>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h2" component="h3">{t('ROUTE_OPTIMIZER')}</Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

TopBar.propTypes = {
};

TopBar.defaultProps = {
};

const mapStateToProps = (store) => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
