import httpService from './http.service';


const postUploadFiles = (clients, activities, plans) => {
    var bodyFormData = new FormData();
    bodyFormData.append('clients', clients); 
    bodyFormData.append('activities', activities); 
    bodyFormData.append('plans', plans); 
    return httpService
    .post(`/compute/all`, bodyFormData, { "Content-Type": "multipart/form-data" })
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));
}

const postUploadTestFiles = (plans) => {
    var bodyFormData = new FormData();
    bodyFormData.append('plans', plans); 
    return httpService
    .post(`/compute/test`, bodyFormData, { "Content-Type": "multipart/form-data" })
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));
}


export default {
    postUploadFiles,
    postUploadTestFiles,
};