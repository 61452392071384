import React from "react";
import './App.css';
import { styled } from '@mui/system';
import { TopBar } from './components/layout';
import UploadFiles from './containers/upload'
import { Navigate, Routes, Route } from 'react-router-dom';
import logo from './img/scrumware.png';

const RootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
}));

const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'auto',
}));

const AppBarSpacer = styled('div')(({ theme }) => ({
  height: 50,
  ...theme.mixins.toolbar,
}));

const AppContent = styled('div')(({ theme }) => ({
  height: 'calc(100% - 120px)',
  padding: theme.spacing(1.875),
  paddingTop: 60,
  '& > .scrollbar-container': {
    padding: theme.spacing(1.875),
  },
}));

function App() {
  return (
    <div className="App">
      <RootDiv>
        <TopBar />
        <MainContent>
          <AppBarSpacer />
          <AppContent>
            <Routes >
              <Route path={`/`} element={<Navigate to={`/${process.env.REACT_APP_PATH}`} />} />
              <Route exact path={`/${process.env.REACT_APP_PATH}`} element={<UploadFiles />} />
            </Routes>
          </AppContent>
        </MainContent>
      </RootDiv>
    </div>
  );
}

export default App;

