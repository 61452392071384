import React from 'react';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

const drawerColor = process.env.REACT_APP_ENV_RIBBON_COLOR === 'development' ? '#2B2F3A' : '#FAFAFA';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#008bf6',
      light: '#009aff',
      dark: '#0479e2',
    },
    secondary: {
      main: '#f66a00',
      light: '#fc7b00',
      dark: '#ed4e01',
    },
  },
});


export default props => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
);
