import httpService from './http.service';


const getStatus = () => httpService
    .get(`/status`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));
    
export default {
    getStatus,
};
