import types from '../actionTypes';
import uploadService from '../../services/upload.service';

export const uploadFiles = (clients, activities, plans) => (dispatch, getState) => {
    if (getState().uploadData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.UPLOAD_REQUEST,
    });

    return uploadService.postUploadFiles(clients, activities, plans)
        .then(() => {
            dispatch({
                type: types.UPLOAD_SUCCESS,
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.UPLOAD_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const uploadTestFiles = (plans) => (dispatch, getState) => {
    if (getState().uploadData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.UPLOAD_REQUEST,
    });

    return uploadService.postUploadTestFiles(plans)
        .then(() => {
            dispatch({
                type: types.UPLOAD_SUCCESS,
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.UPLOAD_FAIL,
                payload: { error },
            });

            throw error;
        });
};
