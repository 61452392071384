import types from '../actionTypes';
import statusService from '../../services/status.service';

export const fetchStatus = () => (dispatch, getState) => {
    if (getState().statusData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.STATUS_FETCH_REQUEST,
    });

    return statusService.getStatus()
        .then((status) => {
            dispatch({
                type: types.STATUS_FETCH_SUCCESS,
                payload: { status },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.STATUS_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};
