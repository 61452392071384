import {combineReducers} from 'redux';

import authData from './auth';
import statusData from './status';
import uploadData from './upload';


export default () => combineReducers({
    authData,
    uploadData,
    statusData,
});
