import React from "react";
import { Loading } from '../../components/elements';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Typography, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchStatus } from '../../redux/actions/status';
import { uploadFiles, uploadTestFiles } from '../../redux/actions/upload';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RouteIcon from '@mui/icons-material/Route';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';

const UploadFiles = (props) => {
    const {
        status,
        fetchStatus,
        uploadFiles,
        uploadTestFiles,
    } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [isComputing, setIsComputing] = useState(false);
    const [isFetchedStatus, setIsFetchedStatus] = useState(false);
    const [errors, setErrors] = useState([]);
    const { t } = useTranslation();

    const [files, setFiles] = useState({ clients: null, activities: null, plans: null });

    useEffect(() => {
        if (!isFetchedStatus) {
            setIsLoading(true);
            fetchStatus().then(() => {
                setIsFetchedStatus(true);
            }).catch((err) => {
                console.log(err);
                setIsFetchedStatus(true);
                setIsLoading(false)
            });
        }
    }, [isFetchedStatus, fetchStatus]);

    console.log("files", files);

    if (!isLoading) {
        return (
            <Loading />
        );
    }

    const createHandleChange = (fileType) => {
        return (event) => {
            setFiles({ ...files, [fileType]: event.target.files[0] });
        }
    }

    const handleTestSubmit = () => {
        console.log("handleTestSubmit");
        setErrors([]);
        setIsComputing(true);
        setIsFetchedStatus(false);
        uploadTestFiles(files.plans)
        .then(() => {
            setIsComputing(false);
            setIsFetchedStatus(false);
        }).catch((err) => {
            console.log(err);
            if (err.data && Array.isArray(err.data))
                setErrors(err.data);
            setIsComputing(false);
            setIsFetchedStatus(false);
        });
    };

    const handleSubmit = (event) => {
        console.log("handleSubmit");
        setErrors([]);
        setIsComputing(true);
        setIsFetchedStatus(false);
        event.preventDefault();
        uploadFiles(files.clients, files.activities, files.plans)
        .then(() => {
            setIsComputing(false);
            setIsFetchedStatus(false);
        }).catch((err) => {
            console.log(err);
            if (err.data && Array.isArray(err.data))
                setErrors(err.data);
            setIsComputing(false);
            setIsFetchedStatus(false);
        });
    };

    return (

        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <RouteIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Fill required data
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {t("STATE")}
                        </Grid>
                        <Grid item xs={6}>
                            {status.serviceBusy ? t("STATE_BUSY") : t("STATE_IDLE")}
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" component="label" color="primary" fullWidth>
                                {t("FILE_SELECT")}
                                <input required id="clients" type="file" hidden onChange={createHandleChange("clients")} />
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            {files.clients !== null ? files.clients.name : t("FILE_SELECT_CLIENTS")}
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" component="label" color="primary" fullWidth>
                                {t("FILE_SELECT")}
                                <input required id="activities" type="file" hidden onChange={createHandleChange("activities")} />
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            {files.activities !== null ? files.activities.name : t("FILE_SELECT_ACTIVITIES")}
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" component="label" color="primary" fullWidth>
                                {t("FILE_SELECT")}
                                <input required id="plans" type="file" hidden onChange={createHandleChange("plans")} />
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            {files.plans !== null ? files.plans.name : t("FILE_SELECT_PLANS")}
                        </Grid>
                    </Grid>
                    <Button
                        id="submit"
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={status.serviceBusy ||
                            files.clients === null ||
                            files.activities === null ||
                            files.plans === null ||
                            isComputing}
                        onClick={handleSubmit}
                    >
                        Compute
                    </Button>
                    <Button
                        id="test-submit"
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={status.serviceBusy ||
                            files.plans === null ||
                            isComputing}
                        onClick={handleTestSubmit}
                    >
                        Test Compute
                    </Button>
                </Box>
            </Box>
            {Array.isArray(errors) && errors.length > 0 && errors.map(err => (<div style={{color: 'red'}}>{err}</div>))}
        </Container>
    );
}

UploadFiles.propTypes = {
    user: PropTypes.object,
};

UploadFiles.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    status: store.statusData.status,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStatus,
    uploadFiles,
    uploadTestFiles,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UploadFiles);