import Keycloak from 'keycloak-js';
import i18n from '../i18n';

import actionTypes from '../redux/actionTypes';
import httpService from "./http.service";

const keycloakUrl = process.env.REACT_APP_KEYCLOAK;
const keycloakRN = process.env.REACT_APP_KEYCLOAK_RN;

const token = localStorage.getItem('kc_token');
const refreshToken = localStorage.getItem('kc_refreshToken');
const timeSkew = 0;

// Setup Keycloak instance as needed
export const keycloak = new Keycloak({
    clientId: 'routeoptimizer-front',
    realm: keycloakRN,
    bearerOnly: true,
    url: keycloakUrl,
    'auth-server-url': keycloakUrl,
    'ssl-required': 'none',
    resource: 'routeoptimizer-front',
    'public-client': false,
    // "confidential-port": 0,
    'enable-cors': true,
    'cors-allowed-methods': 'GET, PUT, POST, HEAD, OPTIONS',
    'cors-allowed-headers': 'Origin, Accept, X-Requested-With, Content-Type, Last-Event-ID',
    checkLoginIframe: false,
    onLoad: 'login-required',
    token: token || '',
    refreshToken: refreshToken || '',
    timeSkew,
});

export const checkKeycloakAuthentication = () => {
    try {
        if (!keycloak.isTokenExpired(30)) {
            return;
        }
    } catch (err ) {
        console.log('error expired token check', err);    
    }

    try {
        updateToken();
    } catch (err) {
        console.log('checkKeycloakAuthentication 03', err);
        throw err;
    };
};

export const updateToken = (callback, callbackWhenNotRefreshed, minValidity) => {
    keycloak.updateToken(minValidity || 30).then((refreshed) => {
        if (refreshed) {
            callback && callback(localStorage.getItem('kc_token'));
        } else {
            callbackWhenNotRefreshed && callbackWhenNotRefreshed();
        }

    }).catch((err) => {
        console.log('update token error', err);
        throw err;
    });
}

export const keycloakInitConfig = {
    onLoad: 'login-required',
    token: token || '',
    refreshToken: refreshToken || '',
    timeSkew,
    checkLoginIframe: false,
};

export const logout = (store) => {
    store.dispatch({
        type: actionTypes.LOG_OUT,
    });
    localStorage.removeItem('sip_calls');
    localStorage.removeItem('kc_token');
    localStorage.removeItem('kc_idToken');
    localStorage.removeItem('kc_refreshToken');

    keycloak.logout();
};

export const onKeycloakTokens = (tokens) => {
    if (tokens.token && tokens.idToken && tokens.refreshToken) { // TODO sort of workaround. has to be fixed properly
        localStorage.setItem('kc_token', tokens.token);
        localStorage.setItem('kc_idToken', tokens.idToken);
        localStorage.setItem('kc_refreshToken', tokens.refreshToken);
    }
};

